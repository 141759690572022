import { useEffect, useState } from 'react'
import Error from '../../assets/error.png'
import { BadgeCheck, ExternalLink, TriangleAlert } from 'lucide-react'
import ButtonWrapper from '../common/Wrappers/Button/ButtonWrapper'
import { cn } from '@/lib/utils'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx'
import SocialIcon from '../common/ui/social-icon'
import FullImagePreview from './FullImagePreview'
import CheckboxWrapper from '@/components/common/Wrappers/Checkbox/CheckboxWrapper.jsx'
import { Link, useNavigate } from 'react-router-dom'

export default function NsfwImageSection({
  hideSensitiveContent,
  imgFallback,
  img,
  intermediateFallbackImg,
  size = 'large',
  handleNavigation,
  id,
  category,
  title,
  canPreviewFullImage = false,
  imgPointer = false,
  checkbox = false,
  checkboxData,
  showMetaData = false,
  takenDown = false,
  takedown_id,
  className,
  link
}) {
  const navigate = useNavigate()

  const [showImage, setShowImage] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [fallbackImageError, setFallbackImageError] = useState(false)
  const [intermediateFallbackImageError, setIntermediateFallbackImageError] =
    useState(false)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setShowImage(!hideSensitiveContent)
  }, [hideSensitiveContent])

  const handleImageError = () => {
    setImageError(true)
  }

  const handleIntermediateFallbackImageError = () => {
    setIntermediateFallbackImageError(true)
  }

  const handleFallbackImageError = () => {
    setFallbackImageError(true)
  }

  const handleImageLoad = () => {
    setLoading(false)
  }

  let imageSrc = img

  if (imageError && !intermediateFallbackImageError) {
    imageSrc = intermediateFallbackImg
  } else if (intermediateFallbackImageError && !fallbackImageError) {
    imageSrc = imgFallback
  } else if (fallbackImageError) {
    imageSrc = Error
  }

  const handleClick = (e) => {
    e.stopPropagation()
    setShowImage(!showImage)
  }

  const navigateToTakedown = (e) => {
    if (takenDown && takedown_id) {
      e.stopPropagation()
      navigate(`/takedown/${takedown_id}?selectedTab=media`)
    }
  }

  return (
    <div
      className={cn(
        'overflow-hidden relative rounded-xl cursor-pointer group',
        {
          'w-64 h-64 cursor-default': size === 'large',
          'w-16 h-16': size === 'small',
          'w-[120px] h-[200px]': size === 'medium'
        },
        className
      )}
    >
      {checkbox && !takenDown && (
        <div className='absolute top-3 left-3 bg-black bg-opacity-20 w-6 h-6 flex items-center justify-center rounded-md cursor-default z-10'>
          <CheckboxWrapper
            id={checkboxData.id}
            checked={checkboxData.checked}
            onCheckedChange={checkboxData.onCheckedChange}
          />
        </div>
      )}

      <div className='w-full h-full relative'>
        {loading && !imageError && (
          <div className='w-full absolute flex justify-center items-center h-full bg-gradient-to-t from-black/50 to-transparent'>
            <SkeletonWrapper number={1} height='100%' width='100%' />
          </div>
        )}
        {canPreviewFullImage && category !== 'adult' ? (
          <FullImagePreview src={imageSrc} />
        ) : null}
        <img
          className={cn(
            'w-full h-full object-cover hover:brightness-100 transition-all duration-150',
            {
              'blur-2xl brightness-100':
                !showImage && category === 'adult' && !takenDown,
              'cursor-pointer': imgPointer
            },
            takenDown && 'grayscale opacity-10'
          )}
          src={imageSrc || Error}
          alt='img'
          onError={() => {
            if (!imageError) {
              handleImageError()
            } else if (!intermediateFallbackImageError) {
              handleIntermediateFallbackImageError()
            } else {
              handleFallbackImageError()
            }
          }}
          onClick={() => {
            handleNavigation && handleNavigation(id)
          }}
          onLoad={handleImageLoad}
        />

        {!loading && (
          <>
            {category === 'adult' && (
              <div
                className={`absolute top-1/2 right-1/2 transform translate-x-1/2 select-none -translate-y-1/2 ${showImage && 'opacity-0'}`}
              >
                <ButtonWrapper
                  className=''
                  icon={<TriangleAlert className='text-white' />}
                  variant='translucent'
                  size='icon'
                  onClick={(e) => {
                    handleClick(e)
                  }}
                />
              </div>
            )}
            {(size === 'medium' || showMetaData) && !takenDown && (
              <div className='w-full absolute bottom-0 flex flex-col items-start justify-end p-2 bg-gradient-to-t from-black to-transparent h-20 group-hover:h-24'>
                {category ? (
                  <Link to={link} target='_blank' rel='noopener noreferrer'>
                    <div className='absolute bottom-2 left-2 flex items-center gap-2'>
                      <SocialIcon
                        className={cn('opacity-80 group-hover:opacity-100', {
                          'w-4 h-4': category !== 'adult',
                          'w-6 h-6': category === 'adult'
                        })}
                        platform={category}
                        fill='white'
                      />
                      <p className='text-xs line-clamp-1 text-wrap text-white opacity-0 group-hover:opacity-90 transition-opacity duration-300'>
                        {category === 'adult'
                          ? 'Sensitive Content'
                          : category.charAt(0).toUpperCase() +
                            category.slice(1)}
                      </p>
                      <ExternalLink
                        className={
                          'text-muted w-4 h-4 opacity-0 group-hover:opacity-90 transition-opacity duration-300'
                        }
                      />
                    </div>
                  </Link>
                ) : null}
                <div className='absolute w-full left-0 bottom-[-100px] group-hover:bottom-7 p-2 text-white transition-all duration-300 ease-in-out'>
                  <p className='text-xs line-clamp-3 text-wrap'>{title}</p>
                </div>
              </div>
            )}

            {takenDown && (
              <div className='absolute top-0 left-0 w-full h-full flex justify-center bg-white bg-opacity-10 z-20'>
                <div className='flex flex-col items-center justify-center w-full h-full text-center px-8 gap-4'>
                  <div className='bg-gray-300 rounded-full p-2'>
                    <BadgeCheck className='w-8 h-8 text-white' />
                  </div>

                  <p className='text-xs text-gray-400 line-clamp-2 text-wrap'>
                    This content has been taken down
                  </p>

                  <div className='px-4 py-2 w-full opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                    <ButtonWrapper
                      variant='default'
                      text='View Details'
                      onClick={navigateToTakedown}
                      className='h-8 w-auto cursor-pointer'
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
