import { FileQuestion } from 'lucide-react'
import InstagramIcon from '@/assets/icons/InstagramIcon'
import YoutubeIcon from '@/assets/icons/YoutubeIcon'
import TikTokIcon from '@/assets/icons/TikTokIcon'
import AdultIcon from '@/assets/icons/AdultIcon'
import MetaIcon from '@/assets/icons/MetaIcon'
import PinterestIcon from '@/assets/icons/PinterestIcon'
import RedditIcon from '@/assets/icons/RedditIcon.jsx'
import EbayIcon from '@/assets/icons/EbayIcon.jsx'

export default function SocialIcon({
  platform,
  fill = '#71717a',
  className = 'w-4 h-4'
}) {
  if (platform.toLowerCase() === 'instagram')
    return <InstagramIcon fill={fill} className={className} />
  if (platform.toLowerCase() === 'youtube')
    return <YoutubeIcon fill={fill} className={className} />
  if (platform.toLowerCase() === 'tiktok')
    return <TikTokIcon fill={fill} className={className} />
  if (platform.toLowerCase() === 'facebook')
    return <MetaIcon fill={fill} className={className} />
  if (platform.toLowerCase() === 'adult')
    return <AdultIcon fill={fill} className={className} />
  if (platform.toLowerCase() === 'pinterest')
    return <PinterestIcon fill={fill} className={className} />
  if (platform.toLowerCase() === 'reddit')
    return <RedditIcon fill={fill} className={className} />
  if (platform.toLowerCase() === 'ebay') return <EbayIcon fill={fill} />
  return <FileQuestion fill={fill} className={className} />
}
