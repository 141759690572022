import { useStore } from 'zustand'
import NsfwImageSection from '../NsfwImage/NsfwImage'
import globalStore from '@/zustand/globalStore'
export default function ContentCard({ handleNavigation, item, name }) {
  const { hideSensitiveContent, showFrames } = useStore(globalStore)
  return (
    <NsfwImageSection
      category={item.category}
      handleNavigation={handleNavigation}
      id={item?.search_results_id}
      img={showFrames ? item?.frame_url : item?.face_url}
      intermediateFallbackImg={item?.image_url}
      imgFallback={item?.frame_url}
      title={item.category === 'adult' ? item.domain : item?.title}
      size='medium'
      hideSensitiveContent={hideSensitiveContent}
      name={name}
      canPreviewFullImage={true}
      link={item.page_url}
    />
  )
}
