import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const statesToPersist = [
  'auth',
  'userMetaData',
  'hideSensitiveContent',
  'delegate',
  'parentDelegate',
  'showFrames'
]
const globalStore = create(
  persist(
    (set) => ({
      auth: null,
      setAuth: (auth) => set({ auth: auth }),

      selectedTab: 'home',
      setSelectedTab: (value) => set({ selectedTab: value }),

      assets: null,
      setAssets: (value) => set({ assets: value }),

      mail: null,
      setMail: (value) => set({ mail: value }),

      takedowns: null,
      setTakedowns: (value) => set({ takedowns: value }),

      userMetaData: null,
      setUserMetaData: (value) => set({ userMetaData: value }),

      dashboardStats: null,
      setDashboardStats: (value) => set({ dashboardStats: value }),

      tokenExpired: false,
      setTokenExpired: (value) => set({ tokenExpired: value }),

      loading: false,
      setLoading: (value) => set({ loading: value }),

      showCompleteProfileSection: true,
      setShowCompleteProfileSection: (value) =>
        set({ showCompleteProfileSection: value }),

      userData: null,
      setUserData: (value) => set({ userData: value }),

      cross_token: null,
      setCross_token: (value) => set({ cross_token: value }),

      hideSensitiveContent: true,
      setHideSensitiveContent: (value) => set({ hideSensitiveContent: value }),

      delegates: null,
      setDelegates: (value) => set({ delegates: value }),

      delegate: null,
      setDelegate: (value) => set({ delegate: value }),

      parentDelegate: null,
      setParentDelegate: (value) => set({ parentDelegate: value }),

      showFrames: false,
      setShowFrames: (value) => set({ showFrames: value }),

      clear: () => {
        set({
          auth: null,
          userData: null,
          loading: false,
          dashboardVideos: null,
          dashboardDeepfakes: null,
          dashboardStats: null,
          userMetaData: null,
          takedowns: null,
          mail: null,
          tokenExpired: false,
          showCompleteProfileSection: true,
          cross_token: null
        })
      }
    }),
    {
      name: 'global-store',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) =>
            statesToPersist?.includes(key)
          )
        )
    }
  )
)
export default globalStore
