import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper.jsx'
import { AlertDialogWrapper } from '@/components/common/Wrappers/AlertDialog/AlertDialog.jsx'

const ActionDock = ({
  dataLength,
  primaryAction,
  secondaryActions,
  tertiaryAction,
  selectedTab
}) => {
  return (
    <div className='w-fit max-w-[100vw] px-4 md:px-10 h-[4rem] border shadow-xl flex items-center sm:gap-6 gap-3 justify-center bg-background rounded-lg'>
      <div className='text-sm font-medium'>
        <p className={'text-wrap text-xs sm:text-sm'}>
          {dataLength} results selected
        </p>
      </div>
      {primaryAction && (
        <div className={'flex gap-4'}>
          <AlertDialogWrapper
            trigger={
              <ButtonWrapper
                text={primaryAction.text}
                size={'sm'}
                disabled={primaryAction.disabled}
                tooltip={primaryAction.disabled}
                tooltipText={
                  'The selection contains results that are not eligible for takedown.'
                }
                tooltipDelayDuration={100}
              />
            }
            alertTitle={primaryAction.alertTitle}
            alertDescription={primaryAction.alertDescription}
            alertOk={primaryAction.alertOk}
            alertCancel={primaryAction.alertCancel}
            handleOk={primaryAction.handlePrimary}
            disabled={primaryAction.disabled}
          />
          {selectedTab === 'deepfake' && (
            <AlertDialogWrapper
              trigger={<ButtonWrapper text={tertiaryAction.text} size={'sm'} />}
              alertTitle={tertiaryAction.alertTitle}
              alertDescription={tertiaryAction.alertDescription}
              alertOk={tertiaryAction.alertOk}
              alertCancel={tertiaryAction.alertCancel}
              handleOk={tertiaryAction.handlePrimary}
            />
          )}
        </div>
      )}
      {secondaryActions &&
        secondaryActions.map((action, index) => (
          <AlertDialogWrapper
            key={index}
            trigger={
              <ButtonWrapper
                text={action.text}
                variant={action.variant}
                icon={action.icon}
                size={'sm'}
              />
            }
            alertTitle={action.alertTitle}
            alertDescription={action.alertDescription}
            alertOk={action.alertOk}
            alertCancel={action.alertCancel}
            handleOk={action.handlePrimary}
            disabled={action.disabled}
          />
        ))}
    </div>
  )
}

export default ActionDock
