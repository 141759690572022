const AdultIcon = ({ fill = 'currentColor', ...rest }) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M16.9132 2H14.9132V0H13.5798V2H11.5798V3.33333H13.5798V5.33333H14.9132V3.33333H16.9132V2Z'
        fill={fill}
      />
      <path
        d='M6.14898 6.54867H6.13365L5.14165 7.02068L4.94165 6.10934L6.31698 5.46667H7.32432V10.6667H6.14898V6.54867Z'
        fill={fill}
      />
      <path
        d='M8.9585 9.30734C8.95856 9.02069 9.04789 8.74117 9.21407 8.50761C9.38025 8.27405 9.61504 8.09803 9.88583 8.004V7.98001C9.66542 7.8826 9.47752 7.7241 9.34437 7.52325C9.21122 7.3224 9.13839 7.08762 9.1345 6.84667C9.1345 5.96734 9.92583 5.38 10.9652 5.38C12.1805 5.38 12.6845 6.084 12.6845 6.71334C12.6819 6.95603 12.6097 7.19287 12.4764 7.39569C12.343 7.5985 12.1543 7.75874 11.9325 7.85734V7.88067C12.2069 7.96955 12.4465 8.14242 12.6173 8.37486C12.7882 8.6073 12.8816 8.88755 12.8845 9.17601C12.8845 10.12 12.0925 10.7513 10.8845 10.7513C9.56583 10.7547 8.9585 10.0113 8.9585 9.30734ZM11.6452 9.26734C11.6452 8.81201 11.3118 8.54001 10.8298 8.41201C10.6511 8.44823 10.4904 8.54529 10.3752 8.68666C10.26 8.82802 10.1973 9.00496 10.1978 9.18734C10.1956 9.284 10.2131 9.3801 10.2492 9.46978C10.2854 9.55946 10.3394 9.64084 10.408 9.70896C10.4766 9.77708 10.5584 9.83051 10.6484 9.86599C10.7383 9.90147 10.8345 9.91826 10.9312 9.91534C11.0216 9.92396 11.1129 9.91362 11.1992 9.88496C11.2854 9.85631 11.3647 9.80998 11.432 9.74893C11.4994 9.68788 11.5532 9.61346 11.5901 9.53042C11.6271 9.44738 11.6463 9.35755 11.6465 9.26667L11.6452 9.26734ZM10.2778 6.80467C10.2778 7.15667 10.5972 7.38067 11.0112 7.50867C11.1592 7.46915 11.2905 7.38293 11.3856 7.26283C11.4808 7.14273 11.5346 6.99514 11.5392 6.842C11.5455 6.75705 11.5336 6.67171 11.5042 6.59175C11.4748 6.5118 11.4286 6.43907 11.3687 6.37848C11.3088 6.31789 11.2366 6.27085 11.157 6.24052C11.0774 6.21019 10.9922 6.19729 10.9072 6.20267C10.825 6.19548 10.7423 6.2059 10.6646 6.23323C10.5868 6.26056 10.5157 6.30417 10.4562 6.36116C10.3966 6.41814 10.3499 6.48718 10.3191 6.56366C10.2884 6.64015 10.2743 6.72231 10.2778 6.80467Z'
        fill={fill}
      />
      <path
        d='M15.4031 6.49534C15.7352 7.91147 15.5966 9.39726 15.0085 10.7276C14.4203 12.0579 13.4145 13.1603 12.1436 13.8677C10.8726 14.575 9.40573 14.8489 7.96516 14.6477C6.5246 14.4465 5.18886 13.7812 4.16034 12.7527C3.13182 11.7242 2.46656 10.3885 2.26537 8.9479C2.06419 7.50733 2.33803 6.04044 3.0454 4.76948C3.75278 3.49853 4.85515 2.49277 6.18548 1.9046C7.5158 1.31643 9.00159 1.17791 10.4177 1.51001C10.5511 1.0719 10.759 0.660081 11.0324 0.292675C9.32344 -0.181835 7.50547 -0.0771141 5.86232 0.590487C4.21916 1.25809 2.84331 2.45099 1.94958 3.98295C1.05585 5.5149 0.694536 7.29968 0.922063 9.05863C1.14959 10.8176 1.95314 12.4517 3.20727 13.7058C4.46139 14.9599 6.09549 15.7635 7.85443 15.991C9.61338 16.2185 11.3982 15.8572 12.9301 14.9635C14.4621 14.0697 15.655 12.6939 16.3226 11.0507C16.9902 9.40759 17.0949 7.58962 16.6204 5.88067C16.253 6.15405 15.8412 6.36199 15.4031 6.49534Z'
        fill={fill}
      />
    </svg>
  )
}
export default AdultIcon
