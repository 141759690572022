import { useNavigate } from 'react-router-dom'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore.js'
import globalStore from '@/zustand/globalStore'
import NsfwImageSection from '@/components/NsfwImage/NsfwImage'
import { Checkbox } from '@/components/common/ui/checkbox.jsx'
import { SquareArrowOutUpRight } from 'lucide-react'
import BadgeWrapper from '@/components/common/Wrappers/Badge/BadgeWrapper.jsx'
import TooltipWrapper from '@/components/common/Wrappers/Tooltip/TooltipWrapper'
import SocialIcon from '@/components/common/ui/social-icon'
import { sortByAscAndDesc } from '@/pages/Watchtower/utils'
import { useCheckResultType } from '@/pages/Watchtower/hooks/useCheckResultType.js'

const getThreatLevel = (threatLevel) => {
  if (threatLevel >= 90 || threatLevel === 'critical') {
    return <BadgeWrapper text={'Critical'} variant={'destructive'} />
  } else if (
    (threatLevel >= 80 && threatLevel < 90) ||
    threatLevel === 'high'
  ) {
    return <BadgeWrapper text={'High'} variant={'underreview'} />
  } else if (
    (threatLevel >= 70 && threatLevel < 80) ||
    threatLevel === 'moderate'
  ) {
    return <BadgeWrapper text={'Moderate'} variant={'warning'} />
  } else {
    return null
  }
}

export default function WatchtowerGrid({ data, selectedTab }) {
  const navigate = useNavigate()
  const {
    selectedColumns,
    selectedResults,
    setSelectedResults,
    sortConfig,
    watchtowerData,
    setDisableTakedownButton
  } = useStore(watchtowerStore)
  const { hideSensitiveContent } = useStore(globalStore)
  const handleNavigation = (id) => {
    navigate(`/watchtower/${id}`)
  }

  const handleSelect = (e) => {
    const { checked, id } = e
    if (id === 'all') {
      if (checked) {
        setSelectedResults(
          data.map((item) =>
            selectedTab === 'impersonations'
              ? item.username_platform
              : item.search_results_id
          )
        )
      } else {
        setSelectedResults([])
      }
    } else if (checked) {
      setSelectedResults([...selectedResults, id])
    } else {
      setSelectedResults(selectedResults.filter((item) => item !== id))
    }
  }

  useCheckResultType({
    setDisableTakedownButton,
    selectedResults,
    watchtowerData
  })

  return (
    <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 justify-between pb-8'>
      {sortByAscAndDesc(data, sortConfig, selectedTab)?.length > 0 ? (
        sortByAscAndDesc(data, sortConfig, selectedTab).map((item, index) => (
          <div
            key={index}
            className={`relative w-[10rem] rounded-xl border overflow-hidden shadow hover:shadow-md transition-all ease-in cursor-pointer ${!selectedColumns?.includes('result') ? 'pt-8' : ''}`}
            onClick={() => {
              if (selectedTab === 'impersonations') {
                const encodedId = encodeURIComponent(item.username_platform)
                handleNavigation(
                  `${encodedId}?type=username_platform&takedown=${!!item.takedown_id}&selectedTab=accounts`
                )
              } else {
                handleNavigation(
                  `${item.takedown_id ? item.takedown_id : item.search_results_id}?takedown=${item.takedown_id ? 'true&selectedTab=media' : false}`
                )
              }
            }}
          >
            {selectedColumns?.includes('result') ? (
              <div className='h-[10rem]'>
                <NsfwImageSection
                  hideSensitiveContent={hideSensitiveContent}
                  img={item.face_url || item.profile_image}
                  intermediateFallbackImg={item.image_url}
                  imgFallback={item?.frame_url}
                  size='large'
                  className={'w-full h-full'}
                  category={item.category}
                  imgPointer={true}
                />
              </div>
            ) : null}

            {selectedColumns?.includes('threat_level') && (
              <div className='absolute top-0 right-3 text-foreground text-sm font-normal py-3'>
                <div className='flex items-center'>
                  {selectedTab === 'impersonations'
                    ? getThreatLevel(item?.overall_score)
                    : getThreatLevel(item?.threat_level)}
                </div>
              </div>
            )}

            <div
              className='absolute top-3 left-3 bg-black bg-opacity-20 w-6 h-6 flex items-center justify-center rounded-md cursor-default'
              onClick={(e) => e.stopPropagation()}
            >
              <Checkbox
                id={item?.search_results_id}
                onCheckedChange={(e) =>
                  handleSelect({
                    checked: e,
                    id:
                      selectedTab === 'impersonations'
                        ? item.username_platform
                        : item.search_results_id
                  })
                }
                disabled={
                  !(
                    (selectedTab === 'impersonations' &&
                      (item.category === 'instagram' ||
                        item.category === 'facebook' ||
                        item.category === 'pinterest' ||
                        item.category === 'tiktok')) ||
                    (selectedTab === 'all' && item.category === 'adult') ||
                    selectedTab === 'adult' ||
                    selectedTab === 'deepfake'
                  )
                }
                checked={selectedResults.includes(
                  selectedTab === 'impersonations'
                    ? item.username_platform
                    : item.search_results_id
                )}
                className='bg-white border-white'
              />
            </div>

            <div className='px-3 py-2'>
              {selectedColumns?.includes('result') &&
              selectedTab !== 'impersonations' ? (
                <div className='flex items-center gap-4 h-[2rem] text-sm font-medium'>
                  <p className='line-clamp-2 truncate'>
                    {item.title || 'Unavailable'}
                  </p>
                </div>
              ) : null}

              {selectedColumns?.includes('source') && (
                <div className='text-sm font-medium max-w-36 truncate'>
                  <p
                    onClick={(e) => {
                      e.stopPropagation()
                      window.open(item.page_url, '_blank')
                    }}
                    className='w-fit py-1'
                  >
                    {item?.page_url ? (
                      <TooltipWrapper
                        text={
                          <div className='flex items-center gap-2 hover:text-green-500'>
                            {<SquareArrowOutUpRight className='w-4 h-4' />}
                            <span className='text-xs'>{item?.page_url}</span>
                          </div>
                        }
                        component={
                          <div className='flex items-center justify-start gap-2'>
                            <SocialIcon platform={item?.category} />
                            <span>
                              {item.username
                                ? `@${item.username}`
                                : item?.domain || 'Unavailable'}
                            </span>
                          </div>
                        }
                        delayDuration={20}
                      />
                    ) : null}
                  </p>
                </div>
              )}
              {selectedColumns?.includes('followers') ||
              selectedColumns?.includes('following') ? (
                <>
                  <hr className='my-1' />
                  <div className='grid grid-cols-2 gap-4'>
                    {selectedColumns?.includes('followers') && (
                      <div className='text-sm font-medium max-w-36 truncate'>
                        <p
                          onClick={(e) => {
                            e.stopPropagation()
                            window.open(item.page_url, '_blank')
                          }}
                          className='w-fit py-1'
                        >
                          {item?.followers?.toLocaleString('en-us') ??
                            'Unavailable'}
                        </p>
                        <p className='text-xs text-text-muted'>Followers</p>
                      </div>
                    )}
                    {selectedColumns?.includes('following') && (
                      <div className='text-sm font-medium max-w-36 truncate'>
                        <p
                          onClick={(e) => {
                            e.stopPropagation()
                            window.open(item.page_url, '_blank')
                          }}
                          className='w-fit py-1'
                        >
                          {item?.following?.toLocaleString('en-us') ??
                            'Unavailable'}
                        </p>
                        <p className='text-xs text-text-muted'>Following</p>
                      </div>
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ))
      ) : (
        <p>No data available...</p>
      )}
    </div>
  )
}
