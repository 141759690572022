import { useEffect, useCallback, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import AutoScroll from 'embla-carousel-auto-scroll'

import { Search } from 'lucide-react'

function MediaSearchCarousel({ images }) {
  const [carouselImages, setCarouselImages] = useState(images)

  useEffect(() => {
    if (images.length < 100) {
      const newImages = [...images]

      for (let i = 0; i < 100; i++) {
        newImages.push(images[i % images.length])
      }

      setCarouselImages(newImages)
    } else {
      setCarouselImages(images)
    }
  }, [images])

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
      startIndex: 4,
      draggable: false,
      align: 'center',
      slidesToScroll: 1
    },
    [AutoScroll({ speed: 10, playOnInit: true })]
  )

  const applyClasses = useCallback(() => {
    if (!emblaApi) return

    const slides = emblaApi.slideNodes()
    const selectedIndex = emblaApi.selectedScrollSnap()
    const totalSlides = slides.length

    slides.forEach((slide, index) => {
      let distance = Math.abs(selectedIndex - index)
      if (distance > Math.floor(totalSlides / 2)) {
        distance = totalSlides - distance
      }

      let scale
      switch (distance) {
        case 1:
          scale = 0.8
          break
        case 2:
          scale = 0.6
          break
        case 3:
          scale = 0.5
          break
        case 4:
          scale = 0.4
          break
        case 5:
          scale = 0.3
          break
        case 6:
          scale = 0.2
          break
        default:
          scale = 1
          break
      }

      slide.style.transform = `scale(${scale})`
      slide.style.transition = 'transform 0.3s ease'
    })
  }, [emblaApi])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', applyClasses)
      applyClasses()

      emblaApi.on('scroll', () => {
        const totalSlides = emblaApi.slideNodes().length
        const selectedIndex = emblaApi.selectedScrollSnap()

        if (selectedIndex >= totalSlides - 4) {
          emblaApi.scrollTo(4, true)
        }
      })
    }
  }, [emblaApi, applyClasses])

  return (
    <div className='relative overflow-hidden pointer-events-none'>
      <div className='absolute left-0 top-0 bottom-0 h-full w-64 z-10 bg-gradient-to-r from-white to-transparent' />
      <div className='absolute right-0 top-0 bottom-0 h-full w-64 z-10 bg-gradient-to-r from-transparent to-white' />

      <div className='overflow-hidden box-border' ref={emblaRef}>
        <div className='relative flex gap-4 items-center'>
          {carouselImages?.map((src, index) => (
            <div
              className='relative h-24 flex-shrink-0 transition-all'
              key={index}
            >
              <img
                src={src}
                alt={`Slide ${index}`}
                className='rounded-2xl w-full h-full object-cover'
                loading='lazy'
                onError={(e) => {
                  e.target.parentElement.style.display = 'none'
                }}
              />
            </div>
          ))}
        </div>

        <div
          className='absolute top-0 left-0 right-0 ml-auto mr-auto w-24 h-24 bg-white z-20 border-black border-2 rounded-2xl flex items-center justify-center'>
          <Search className='w-10 h-10 text-black' />
        </div>
      </div>
    </div>
  )
}

export default MediaSearchCarousel
