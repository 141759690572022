import { useMemo } from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation
} from 'react-router-dom'
import './App.css'
import globalStore from './zustand/globalStore.js'
import 'react-toastify/dist/ReactToastify.css'
import { SideNav } from './components/SideNav/SideNav.jsx'
import Auth from './pages/Auth/Auth.jsx'
import { useStore } from 'zustand'
import routes from './routes/routes.js'
import { cn } from './lib/utils'
import { useCrossTokenFunctionality } from './Hooks/useCrossTokenFunctionality'
import { Toaster } from 'sonner'
import useKeyboardShortcut from '@/Hooks/useKeyboardShortcut.js'
const Container = ({ children }) => {
  const location = useLocation()
  const isVaultPage = location.pathname === '/vault'
  return (
    <div
      className={cn('flex w-full h-full justify-center overflow-hidden', {
        'py-8': !isVaultPage
      })}
    >
      {children}
    </div>
  )
}
const App = () => {
  const { auth, showFrames, setShowFrames } = useStore(globalStore)
  const isAuthenticated = !!auth?.session_token
  const privateRoutes = useMemo(() => {
    return routes.map((route) => {
      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            <Container>
              <route.component />
            </Container>
          }
        />
      )
    })
  }, [])

  const queryParams = new URLSearchParams(location.search)
  const tokenFromParams = queryParams.get('agent-token')
  const isLoginRoute = location.pathname === '/login'
  useCrossTokenFunctionality()
  useKeyboardShortcut(
    () => {
      setShowFrames(!showFrames)
    },
    {
      ctrl: true,
      alt: true,
      shift: true,
      key: 'Z'
    }
  )

  return (
    <BrowserRouter>
      <Toaster />
      <div className='flex flex-col lg:flex-row'>
        {isAuthenticated && <SideNav />}
        <Routes>
          {isAuthenticated && !isLoginRoute ? (
            <>{privateRoutes}</>
          ) : (
            <>
              {isLoginRoute && tokenFromParams ? (
                <Route path='/login' element={<Auth />} />
              ) : (
                <>
                  <Route path='/login' element={<Auth />} />
                  <Route path='*' element={<Navigate to='/login' replace />} />
                </>
              )}
            </>
          )}
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
