import { useEffect } from 'react'

const useKeyboardShortcut = (callback, combination) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      const { altKey, shiftKey, key, ctrlKey } = event
      if (
        combination.ctrl === ctrlKey &&
        combination.alt === altKey &&
        combination.shift === shiftKey &&
        combination.key.toLowerCase() === key.toLowerCase()
      ) {
        callback()
        window.location.reload()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [callback, combination])
}
export default useKeyboardShortcut
