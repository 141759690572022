const EbayIcon = ({
  fill = "currentColor",
  width = 28,
  height = 28,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 24 24"
      id="ebay"
      fill={fill}
      width={width}
      height={height}
      {...rest}
    >
      <path d="M3 15c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3a.5.5 0 0 1-.5.5H1.063A2.003 2.003 0 0 0 3 14c1.27 0 1.623-.192 2.106-.809a.5.5 0 1 1 .787.617C5.178 14.722 4.502 15 3 15zm-1.937-3.5h3.873C4.714 10.638 3.93 10 3 10s-1.714.638-1.937 1.5z"></path>
      <path d="M9 15c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.898-2-2-2z"></path>
      <path d="M6.5 15a.5.5 0 0 1-.5-.5v-7a.5.5 0 1 1 1 0v7a.5.5 0 0 1-.5.5zM17.5 15a.5.5 0 0 1-.5-.5v-3c0-.99-1.402-1.5-2-1.5-.797 0-1.278.229-1.563.742a.5.5 0 1 1-.875-.483C13.023 9.423 13.844 9 15 9c1.077 0 3 .835 3 2.5v3a.5.5 0 0 1-.5.5z"></path>
      <path d="M14.5 15c-2.175 0-2.5-1.253-2.5-2 0-.335.13-.638.376-.874.76-.729 2.506-.722 4.376-.648.294.012.551.022.748.022a.5.5 0 0 1 .433.75c.069.12.089.267.042.408-.271.81-1.344 2.342-3.475 2.342zm.925-2.555c-.963 0-2.014.073-2.355.402-.058.055-.07.1-.07.153 0 .495.178 1 1.5 1 1.605 0 2.272-1.104 2.465-1.513l-.253-.01c-.34-.014-.803-.032-1.287-.032zM20.5 14.5a.5.5 0 0 1-.416-.223l-3-4.5a.5.5 0 1 1 .832-.554l3 4.5a.5.5 0 0 1-.416.777z"></path>
      <path d="M19.5 17a.5.5 0 0 1-.435-.748l4-7a.5.5 0 1 1 .87.496l-4 7A.501.501 0 0 1 19.5 17z"></path>
    </svg>
  );
};
export default EbayIcon;
