import { useStore } from 'zustand'
import ReviewedTabs from '@/pages/Watchtower/pages/Home/components/Tabs/FilterTab/ReviewedTabs/ReviewedTabs.jsx'
import watchtowerStore from '../../watchtowerStore'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFetchSearchSummary } from '@/pages/Home/hooks/useFetchSearchSummary'
import {
  useFetchSearches,
  useSetTabFromQuery
} from '@/pages/Watchtower/hooks/useWatchtowerHooks'
import { computeCounts, fetchSearches } from '../../utils'
import { createTakedown } from '@/redux/Takedowns/actions.js'
import FilterTab from '@/pages/Watchtower/pages/Home/components/Tabs/FilterTab/FilterTab.jsx'
import ReviewRequiredTabs from '@/pages/Watchtower/pages/Home/components/Tabs/FilterTab/ReviewRequiredTabs/ReviewRequiredTabs.jsx'
import Pagination from '@/components/Pagination/Pagination.jsx'
import { useRef, useState } from 'react'
import { setIsMe, setIsNotDeepfake } from '@/redux/Watchtower/actions.js'
import DialogWrapper from '@/components/common/Wrappers/Dialog/DialogWrapper.jsx'
import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper.jsx'
import { SearchIcon } from 'lucide-react'
import InputWrapper from '@/components/common/Wrappers/Input/InputWrapper.jsx'
import { cn } from '@/lib/utils.js'
import MediaSearchCarousel from './components/MediaSearchCarousel/MediaSearchCarousel'

export default function Watchtower() {
  const {
    watchtowerData,
    setWatchtowerData,
    selectedTab,
    setSelectedTab,
    searchSummary,
    loading,
    selectedResults,
    setSelectedResults,
    setMarkedForDelete,
    threatLevel,
    selectedFilterTab,
    reviewRequiredData,
    selectedReviewRequiredTab,
    setReviewRequiredData,
    sortConfig,
    setSortConfig,
  } = useStore(watchtowerStore)
  const prevThreatLevel = useRef(threatLevel)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState('')
  const [, setSearchParams] = useSearchParams()
  const counts = computeCounts(searchSummary)

  const data =
    selectedFilterTab === 'results' ? watchtowerData : reviewRequiredData

  const tab =
    selectedFilterTab === 'results' ? selectedTab : selectedReviewRequiredTab

  const setData =
    selectedFilterTab === 'results' ? setWatchtowerData : setReviewRequiredData
  useFetchSearchSummary(false)
  useSetTabFromQuery(setSelectedTab)
  useFetchSearches(
    (page, tab) =>
      fetchSearches({
        dispatch,
        tab,
        page
      }),
    prevThreatLevel
  )
  const handlePageChange = (page) => {
    setData(tab, { currentPage: page })
    fetchSearches({
      dispatch,
      tab,
      page
    })

    window?.scrollTo(0, 0)
    setSelectedResults([])
    setMarkedForDelete([])
  }

  const handleTabChange = (tab) => {
    setSearchParams({ selectedTab: tab })
    setSelectedTab(tab)
    setSortConfig(
      sortConfig?.filter((item) => item.key === 'followers_sorting')
    )
  }

  const handlePrimary = () => {
    const allTiktok = selectedResults.every((result) =>
      result.endsWith('#tiktok')
    )
    const noTiktok = selectedResults.every(
      (result) => !result.endsWith('#tiktok')
    )
    const searchResultsIds = selectedResults.map(encodeURIComponent).join(',')

    if (allTiktok || noTiktok) {
      const payload = {
        id: searchResultsIds,
        tab: selectedTab,
        key: 'array'
      }
      dispatch(createTakedown({ payload }))
    } else {
      const tiktokResults = selectedResults
        .filter((result) => result.endsWith('#tiktok'))
        .map(encodeURIComponent)
        .join(',')
      const otherResults = selectedResults
        .filter((result) => !result.endsWith('#tiktok'))
        .map(encodeURIComponent)
        .join(',')

      if (tiktokResults) {
        const tiktokPayload = {
          id: tiktokResults,
          tab: selectedTab,
          key: 'array'
        }
        dispatch(createTakedown({ payload: tiktokPayload }))
      }

      if (otherResults) {
        const otherPayload = {
          id: otherResults,
          tab: selectedTab,
          key: 'array'
        }
        dispatch(createTakedown({ payload: otherPayload }))
      }
    }

    setSelectedResults([])
  }

  //* Using for batch select not a deepfake.
  const handleTertiary = () => {
    const searchResultsIds = selectedResults.join(',')
    let payload = {
      ids: searchResultsIds
    }
    dispatch(setIsNotDeepfake({ payload }))
    setSelectedResults([])
  }

  const handleNotAnImpersonator = ({ key = true }) => {
    const ids = selectedResults.join(',')
    dispatch(
      setIsMe({
        payload: {
          ids,
          isMe: key,
          type: 'impersonations',
          tab: 'reviewed'
        }
      })
    )
    setSelectedResults([])
  }

  const suggestions = [
    'Blood',
    'Drugs',
    'Smoking',
    'Nudity',
    'Violence',
    'Guns'
  ].sort((a, b) => a.localeCompare(b))

  const handleClick = (key) => {
    setSearchValue(key)
    navigate(`/search-media/${key}`)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (searchValue) handleClick(e.target.value)
    }
  }
  const carouselImages =
    watchtowerData[selectedTab]?.data?.map((item) => item.face_url) || []

  return (
    <div className='w-full max-w-screen-xl md:px-16 px-6 pb-8'>
      <div className='flex justify-between items-center'>
        <div className=''>
          <p className='text-xl font-semibold text-foreground'>Watchtower</p>
        </div>
        <div className={''}>
          <DialogWrapper
            trigger={
              <ButtonWrapper
                icon={<SearchIcon className={'w-4 h-4'} />}
                className={'py-2 px-4 border rounded-lg'}
                text={'Search Media'}
                disabled={!carouselImages.length}
                size={'small'}
              />
            }
            className={'p-0 m-0 gap-0 max-w-screen-xl w-fit'}
            showCloseButton={false}
            showDescription={false}
            showHeader={false}
          >
            <div className='w-[650px]'>
              <div className='relative'>
                <div className='relative shadow-sm'>
                  <InputWrapper
                    className='w-full h-[3.5rem] border-0 flex items-center px-12 rounded-lg outline-none !ring-0 !ring-offset-0'
                    placeholder={'Keyword or description...'}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className='top-[16px] left-3 absolute'>
                  <SearchIcon className='w-6 h-6' />
                </div>
                <div className='top-[10px] right-2 absolute'>
                  <ButtonWrapper
                    text='Search'
                    disabled={!searchValue?.length}
                    onClick={() => navigate(`/search-media/${searchValue}`)}
                    size={'small'}
                    className={'px-4 py-2'}
                  />
                </div>
              </div>
              <div className='flex gap-2 items-center px-4 py-1 border bg-muted'>
                <div className='text-muted-foreground text-sm font-regular'>
                  <p>Suggestions:</p>
                </div>
                <div className={'flex items-center gap-2 py-2'}>
                  {suggestions.map((suggestion, index) => (
                    <ButtonWrapper
                      key={index}
                      text={suggestion}
                      variant={searchValue === suggestion ? '' : 'outline'}
                      className={cn('px-2 py-[2px] border')}
                      size={'small'}
                      onClick={() => {
                        handleClick(suggestion)
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className='w-full my-8'>
                <MediaSearchCarousel images={carouselImages} />
              </div>

              <div
                className={
                  'w-full flex justify-center items-center flex-col my-8'
                }
              >
                <p className='text-2xl font-medium text-black'>
                  What are you looking for?
                </p>
                <p className='my-2 text-muted-foreground text-regular'>
                  Enter keyword or description to locate specific infringing
                  content.
                </p>
              </div>
            </div>
          </DialogWrapper>
        </div>

        {/* <div className=''>
          <ExportDialog
            category={category}
            setCategory={setCategory}
            format={format}
            setFormat={setFormat}
            disabled={
              watchtowerData[selectedTab]?.loading || counts[selectedTab] === 0
            }
          />
        </div> */}
      </div>

      <div className='w-full flex gap-4 mt-4 h-full'>
        <FilterTab
          results={
            <ReviewedTabs
              onTabChange={handleTabChange}
              counts={counts}
              handlePrimary={handlePrimary}
              handleTertiary={handleTertiary}
              selectedResults={selectedResults}
              handleNotAnImpersonator={handleNotAnImpersonator}
            />
          }
          review_area={<ReviewRequiredTabs />}
        />
      </div>
      <div className='fixed bottom-0 w-[calc(100vw-45px)] md:w-[calc(100vw-125px)] lg:w-[calc(100vw-320px)] xl:max-w-[1160px]'>
        <Pagination
          currentPage={data[tab]?.currentPage}
          handlePageChange={handlePageChange}
          disableNext={data[tab]?.isLastPage || loading || !data[tab]?.data}
          disablePrev={
            data[tab]?.currentPage === 1 || loading || counts[tab] === 0
          }
          pageKeys={Object.keys(data[tab]?.pageKeys)}
          loading={data[tab]?.loading}
        />
      </div>
    </div>
  )
}
