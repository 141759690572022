import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { DateTime } from 'luxon'
import globalStore from '@/zustand/globalStore'
import takedownStore from '@/pages/Takedowns/takedownStore'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore'
import profileStore from '@/pages/Profile/profileStore'
import homeStore from '@/pages/Home/HomeStore'
import _ from 'lodash'

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function getColor(color) {
  return getComputedStyle(document.documentElement).getPropertyValue(color)
}

export function toIntl(number) {
  if (!number) return number
  if (isNaN(number)) return number

  try {
    return Intl.NumberFormat('en-US').format(number)
  } catch (error) {
    return '0'
  }
}

export function formatLastModified(updatedAt) {
  return DateTime.fromJSDate(new Date(updatedAt)).toRelative()
}

export function extractDomain(url) {
  let domain

  if (url.indexOf('://') > -1) {
    domain = url.split('/')[2]
  } else {
    domain = url.split('/')[0]
  }
  domain = domain.split(':')[0]

  return domain
}

export function sortByDate(data) {
  if (!data) {
    return
  }
  return data.sort((a, b) => {
    const dateA = new Date(a.updatedAt)
    const dateB = new Date(b.updatedAt)

    if (dateA < dateB) return 1
    if (dateA > dateB) return -1
    return 0
  })
}

export const getInitials = (name) => {
  if (!name) return ''

  const words = name.split(' ')

  if (words.length === 1) {
    return words[0].charAt(0).toUpperCase()
  }

  const initials =
    words.length > 2
      ? words[0].charAt(0) + words[words.length - 1].charAt(0)
      : words.map((word) => word.charAt(0)).join('')

  return initials.toUpperCase()
}

export const stringToColor = (string) => {
  if (!string) return '#FFFFFF'
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let avatarColor = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    avatarColor += `00${value.toString(16)}`.slice(-2)
  }

  return avatarColor
}

export function formatDate(timestamp) {
  if (!timestamp) {
    return 'N/A'
  }

  const date = DateTime.fromISO(timestamp, { zone: 'utc' })
  return date.toFormat('d LLLL yyyy, h:mm a')
}

export const getDateRange = (value) => {
  const today = DateTime.now()
  let startingDate, endingDate

  switch (value) {
    case 'this_week': {
      startingDate = today.startOf('week').toFormat('MM-dd-yyyy')
      endingDate = today.endOf('week').toFormat('MM-dd-yyyy')
      break
    }
    case 'last_week': {
      startingDate = today
        .minus({ weeks: 1 })
        .startOf('week')
        .toFormat('MM-dd-yyyy')
      endingDate = today
        .minus({ weeks: 1 })
        .endOf('week')
        .toFormat('MM-dd-yyyy')
      break
    }
    case 'this_month': {
      startingDate = today.startOf('month').toFormat('MM-dd-yyyy')
      endingDate = today.endOf('month').toFormat('MM-dd-yyyy')
      break
    }
    case 'last_month': {
      startingDate = today
        .minus({ months: 1 })
        .startOf('month')
        .toFormat('MM-dd-yyyy')
      endingDate = today
        .minus({ months: 1 })
        .endOf('month')
        .toFormat('MM-dd-yyyy')
      break
    }
    default:
      if (value?.from && value?.to) {
        startingDate = value?.from
          ? DateTime.fromJSDate(new Date(value.from))?.toFormat('MM-dd-yyyy')
          : null
        endingDate = value?.to
          ? DateTime.fromJSDate(new Date(value.to))?.toFormat('MM-dd-yyyy')
          : null
      }
  }
  return { startingDate, endingDate }
}

export const updateDataWithMissingDates = (data, startDate, endDate) => {
  const newData = [...data]

  const start = DateTime.fromFormat(startDate, 'MM-dd-yyyy')
  const end = DateTime.fromFormat(endDate, 'MM-dd-yyyy')

  for (let date = start; date <= end; date = date.plus({ days: 1 })) {
    if (!newData.find((item) => item.date === date.toISODate())) {
      newData.push({ date: date.toISODate(), totalResultsCount: 0 })
    }
  }

  newData.sort((a, b) => {
    return DateTime.fromISO(a.date) - DateTime.fromISO(b.date)
  })

  return newData
}
export function convertDateToMMMDD(dateString) {
  const date = new Date(dateString)

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const month = monthNames[date.getMonth()]
  const day = date.getDate()

  return `${month} ${day}`
}

export const clearAllZustandStores = () => {
  globalStore.getState().clear()
  takedownStore.getState().clear()
  watchtowerStore.getState().clear()
  profileStore.getState().clear()
  homeStore.getState().clear()
}

export const getCategoryFromIndexType = (index_type) => {
  if (!index_type) return ''
  const category = _.split(index_type, '_', 2)
  return category[0]
}

export async function getKey(secretKey) {
  const keyBuffer = new Uint8Array(
    secretKey.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
  )
  const key = await crypto.subtle.importKey(
    'raw',
    keyBuffer,
    { name: 'AES-CBC' },
    false,
    ['encrypt', 'decrypt']
  )
  return key
}
function arrayBufferToHex(buffer) {
  return Array.prototype.map
    .call(new Uint8Array(buffer), (x) => ('00' + x.toString(16)).slice(-2))
    .join('')
}

export async function encrypt(text, key) {
  const iv = crypto.getRandomValues(new Uint8Array(16))
  const encodedText = new TextEncoder().encode(text)

  const encrypted = await crypto.subtle.encrypt(
    { name: 'AES-CBC', iv: iv },
    key,
    encodedText
  )

  return {
    iv: arrayBufferToHex(iv),
    encrypted: arrayBufferToHex(encrypted)
  }
}
export async function getEncryptedUserId(user_id) {
  const secretKey = import.meta.env.VITE_ENCRYPTION_KEY
  if (!secretKey) {
    console.error(
      'VITE_SECRET_KEY is not defined in the environment variables.'
    )
    return
  }

  try {
    const key = await getKey(secretKey)
    const encryptedData = await encrypt(user_id, key)
    return JSON.stringify(encryptedData)
  } catch (error) {
    console.error('Error encrypting user_id:', error)
    return null
  }
}
export const formatNumber = (num) => {
  if (!num) return 0
  if (num >= 1e9) return (num / 1e9).toFixed(1) + 'B'
  if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M'
  if (num >= 1e3) return (num / 1e3).toFixed(1) + 'k'
  return num.toString()
}
// export function adjustDates(dates) {
//   const { created, takedown, success } = dates
//
//   const createdDate = created ? DateTime.fromISO(created) : null
//   const takedownDate = DateTime.fromISO(takedown)
//   const successDate = DateTime.fromISO(success)
//
//   // Check if dates are in the correct timeline
//   if (
//     (createdDate &&
//       createdDate <= takedownDate &&
//       takedownDate <= successDate) ||
//     (!createdDate && takedownDate <= successDate)
//   ) {
//     return dates
//   }
//
//   // Adjust dates if necessary
//   const adjustedDates = { ...dates }
//
//   if (!createdDate) {
//     adjustedDates.created = takedownDate.minus({ hours: 6 }).toISO()
//   } else if (createdDate > takedownDate) {
//     adjustedDates.created = takedownDate.minus({ hours: 6 }).toISO()
//   }
//   console.log(
//     {
//       adjustedDates,
//       dates
//     },
//     'FROM UTILS'
//   )
//   return adjustedDates
// }
