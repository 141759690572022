import { Separator } from '@/components/common/ui/separator'
import NSFWImage from '@/components/NsfwImage/NsfwImage.jsx'
import { extractImageName } from '@/pages/Watchtower/utils'
import { useStore } from 'zustand'
import globalStore from '@/zustand/globalStore'
import takedownStore from '@/pages/Takedowns/takedownStore'
export default function ContentMatch({
  image_url,
  face_url,
  url,
  content_check,
  category,
  username,
  frame_url,
  asset_url
}) {
  const { hideSensitiveContent,showFrames } = useStore(globalStore)
  const { selectedTab } = useStore(takedownStore)
  return (
    <div>
      <div className='px-5'>
        <div className='font-medium text-[16px]'>
          <p>
            {content_check
              ? 'Content check: Takedown not yet effective.'
              : 'Infringing content match'}
          </p>
        </div>
        <div className='text-sm py-1 font-normal text-muted-foreground'>
          <p>
            {content_check
              ? "One of Loti's scrapers has confirmed that the content is still present on the URL."
              : 'A scraper picked up a match for your likeness.'}
          </p>
        </div>
        <div className='text-sm pt-3 pb-2 font-medium'>
          <p>Details</p>
        </div>
        <Separator />
        <div className='py-4'>
          <div className=''>
            {selectedTab === 'media' && (
              <div className='grid grid-cols-10'>
                <div className='col-span-2 text-muted-foreground text-sm'>
                  <p>URL Visited</p>
                </div>
                <div className='px-2 col-span-8 text-sm'>
                  <p> {url}</p>
                </div>
              </div>
            )}
            <div className='grid grid-cols-10 pt-2'>
              <div className='col-span-2 text-muted-foreground text-sm'>
                <p>{selectedTab === 'media' ? 'Image' : 'Account'} found</p>
              </div>
              <div className='px-2 col-span-8 text-sm'>
                <>
                  {/* if tab is media ? image_url is asset_url : profile_image_url */}
                  <NSFWImage
                    img={showFrames ? frame_url : image_url}
                    intermediateFallbackImg={frame_url}
                    hideSensitiveContent={hideSensitiveContent}
                    category={category}
                  />
                  {selectedTab === 'media' ? (
                    <p className='text-muted-foreground py-1'>
                      {extractImageName(image_url)}
                    </p>
                  ) : (
                    username
                  )}
                </>
              </div>
            </div>
            {selectedTab === 'media' && (
              <div className='grid grid-cols-10 pt-2'>
                <div className='col-span-2 text-muted-foreground text-sm'>
                  <p>Likeness matched to</p>
                </div>
                <div className='px-2 col-span-8 text-sm'>
                  <>
                    <NSFWImage
                      img={asset_url}
                      hideSensitiveContent={hideSensitiveContent}
                      category={category}
                    />
                    <p className='text-muted-foreground py-1'>
                      {extractImageName(face_url)}
                    </p>
                  </>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
