import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/common/ui/table.jsx'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore.js'
import globalStore from '@/zustand/globalStore'
import NsfwImageSection from '@/components/NsfwImage/NsfwImage'
import { Checkbox } from '@/components/common/ui/checkbox.jsx'
import { SquareArrowOutUpRight } from 'lucide-react'
import BadgeWrapper from '@/components/common/Wrappers/Badge/BadgeWrapper.jsx'
import TooltipWrapper from '@/components/common/Wrappers/Tooltip/TooltipWrapper'
import SocialIcon from '@/components/common/ui/social-icon'

const getThreatLevel = (threatLevel) => {
  if (threatLevel >= 90 || threatLevel === 'critical') {
    return <BadgeWrapper text={'Critical'} variant={'destructive'} />
  } else if (
    (threatLevel >= 80 && threatLevel < 90) ||
    threatLevel === 'high'
  ) {
    return <BadgeWrapper text={'High'} variant={'underreview'} />
  } else if (
    (threatLevel >= 70 && threatLevel < 80) ||
    threatLevel === 'moderate'
  ) {
    return <BadgeWrapper text={'Moderate'} variant={'warning'} />
  } else {
    return null
  }
}

export default function ReviewRequiredTable({ data }) {
  const { markedForDelete, setMarkedForDelete } = useStore(watchtowerStore)
  const { hideSensitiveContent, showFrames } = useStore(globalStore)
  const handleCheck = (search_results_id, username_platform) => {
    const id = search_results_id || username_platform
    const items = markedForDelete.includes(id)
      ? markedForDelete.filter((markedId) => markedId !== id)
      : [...markedForDelete, id]
    setMarkedForDelete(items)
  }

  return (
    <Table className='h-full w-full border-b'>
      <TableHeader>
        <TableRow className='h-full bg-gray-100 hover:bg-muted/50'>
          <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2' />
          <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
            Result
          </TableHead>
          <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
            Followers
          </TableHead>
          <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
            Following
          </TableHead>
          <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
            Source
          </TableHead>
          <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2 cursor-pointer'>
            Threat Level
          </TableHead>
        </TableRow>
      </TableHeader>

      <TableBody className='w-full'>
        {(() => {
          if (!data || data?.length === 0) {
            return (
              <TableRow>
                <TableCell
                  colSpan='10'
                  className='text-center text-foreground text-sm font-normal py-3'
                >
                  No data available...
                </TableCell>
              </TableRow>
            )
          }

          return data?.map((item, index) => (
            <TableRow key={index} className='max-h-[52px] cursor-pointer'>
              <TableCell className='text-foreground text-sm font-normal w-10 truncate py-2'>
                <Checkbox
                  id={item?.search_results_id ?? item?.username_platform}
                  onCheckedChange={() =>
                    handleCheck(item.search_results_id, item?.username_platform)
                  }
                  checked={markedForDelete.includes(
                    item?.search_results_id ?? item?.username_platform
                  )}
                  defaultChecked={markedForDelete.includes(
                    item?.search_results_id ?? item?.username_platform
                  )}
                />
              </TableCell>
              <TableCell className='text-foreground text-sm font-normal max-w-72 truncate py-2'>
                <div className='w-full flex items-center gap-6'>
                  <div className='max-w-[90%] truncate flex items-center gap-6'>
                    <div>
                      <NsfwImageSection
                        hideSensitiveContent={hideSensitiveContent}
                        img={showFrames ? item?.frame_url : item.face_url}
                        size='small'
                        category={item.category}
                      />
                    </div>
                    <p className='truncate'>{item.title || 'Unavailable'}</p>
                  </div>
                </div>
              </TableCell>
              <TableCell className='text-foreground text-sm font-normal py-3'>
                {item?.followers}
              </TableCell>
              <TableCell className='text-foreground text-sm font-normal py-3'>
                {item?.following}
              </TableCell>
              <TableCell className='text-sm font-medium max-w-36 truncate py-3'>
                <p
                  onClick={() => window.open(item.page_url, '_blank')}
                  className='w-fit py-1'
                >
                  {item?.page_url ? (
                    <TooltipWrapper
                      text={
                        <div className='flex items-center gap-2 hover:text-green-500'>
                          <SquareArrowOutUpRight className='w-4 h-4' />
                          <span className='text-xs'>{item?.page_url}</span>
                        </div>
                      }
                      component={
                        <div className='flex items-center justify-start gap-2'>
                          <SocialIcon platform={item?.category} />
                          <span>
                            {item?.username
                              ? `@${item.username}`
                              : item?.domain || 'Unavailable'}
                          </span>
                        </div>
                      }
                      delayDuration={20}
                    />
                  ) : null}
                </p>
              </TableCell>
              <TableCell className='text-foreground text-sm font-normal py-3'>
                <div className='flex items-center'>
                  {getThreatLevel(item?.overall_score)}
                </div>
              </TableCell>
            </TableRow>
          ))
        })()}
      </TableBody>
    </Table>
  )
}
