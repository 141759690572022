import NsfwImageSection from '@/components/NsfwImage/NsfwImage.jsx'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore.js'
import globalStore from '@/zustand/globalStore.js'
import CheckboxWrapper from '@/components/common/Wrappers/Checkbox/CheckboxWrapper.jsx'

const CardSection = ({ item }) => {
  const { markedForDelete, setMarkedForDelete } = useStore(watchtowerStore)
  const { hideSensitiveContent, showFrames } = useStore(globalStore)

  const {
    face_url,
    category,
    search_results_id,
    username_platform,
    page_url,
    frame_url
  } = item
  const handleCheck = () => {
    const id = search_results_id || username_platform
    const items = markedForDelete.includes(id)
      ? markedForDelete.filter((markedId) => markedId !== id)
      : [...markedForDelete, id]
    setMarkedForDelete(items)
  }
  return (
    <div
      className={
        'w-[10rem] rounded-xl border overflow-hidden shadow hover:shadow-md transition-all ease-in my-1 cursor-pointer'
      }
      onClick={handleCheck}
    >
      <div className={'flex justify-between md:justify-start'}>
        <NsfwImageSection
          img={showFrames ? frame_url : face_url}
          size={'large'}
          customHeight={'100%'}
          customWidth={'100%'}
          hideSensitiveContent={hideSensitiveContent}
          title={category === 'adult' ? item.domain : item?.title}
          category={category}
          imgPointer={true}
          canPreviewFullImage={true}
          showMetaData={true}
          link={page_url}
        />
        <div
          className='absolute m-2 bg-black bg-opacity-20 w-6 h-6 flex items-center justify-center rounded-md cursor-default'
          onClick={(e) => e.stopPropagation()}
        >
          <CheckboxWrapper
            id={search_results_id ?? username_platform}
            className={'bg-background'}
            onCheckedChange={handleCheck}
            checked={markedForDelete.includes(
              search_results_id ?? username_platform
            )}
            defaultChecked={markedForDelete.includes(
              search_results_id ?? username_platform
            )}
          />
        </div>
      </div>

      {/* <div className={'px-3 pt-4'}>
        <div className={'flex items-center gap-4 h-[2rem] text-sm font-medium'}>
          <p className={'line-clamp-2'}>{title || 'Unavailable'}</p>
        </div>
        <div
          className={
            'text-sm flex items-center gap-2 py-3 text-muted-foreground '
          }
        >
          <SocialIcon platform={category} />
          <p>{_.upperFirst(category)}</p>
        </div>
      </div> */}
    </div>
  )
}

export default CardSection
