const InstagramIcon = ({ fill = 'currentColor', ...rest }) => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M7.91611 3.40626C5.92861 3.40626 4.32549 5.00938 4.32549 6.99688C4.32549 8.98438 5.92861 10.5875 7.91611 10.5875C9.90361 10.5875 11.5067 8.98438 11.5067 6.99688C11.5067 5.00938 9.90361 3.40626 7.91611 3.40626ZM7.91611 9.33126C6.63174 9.33126 5.58174 8.28438 5.58174 6.99688C5.58174 5.70938 6.62861 4.66251 7.91611 4.66251C9.20361 4.66251 10.2505 5.70938 10.2505 6.99688C10.2505 8.28438 9.20049 9.33126 7.91611 9.33126ZM12.4911 3.25938C12.4911 3.72501 12.1161 4.09688 11.6536 4.09688C11.188 4.09688 10.8161 3.72188 10.8161 3.25938C10.8161 2.79688 11.1911 2.42188 11.6536 2.42188C12.1161 2.42188 12.4911 2.79688 12.4911 3.25938ZM14.8692 4.10938C14.8161 2.98751 14.5599 1.99376 13.738 1.17501C12.9192 0.356256 11.9255 0.100006 10.8036 0.0437561C9.64736 -0.0218689 6.18174 -0.0218689 5.02549 0.0437561C3.90674 0.0968811 2.91299 0.353131 2.09111 1.17188C1.26924 1.99063 1.01611 2.98438 0.959863 4.10626C0.894238 5.26251 0.894238 8.72813 0.959863 9.88438C1.01299 11.0063 1.26924 12 2.09111 12.8188C2.91299 13.6375 3.90361 13.8938 5.02549 13.95C6.18174 14.0156 9.64736 14.0156 10.8036 13.95C11.9255 13.8969 12.9192 13.6406 13.738 12.8188C14.5567 12 14.813 11.0063 14.8692 9.88438C14.9349 8.72813 14.9349 5.26563 14.8692 4.10938ZM13.3755 11.125C13.1317 11.7375 12.6599 12.2094 12.0442 12.4563C11.1224 12.8219 8.93486 12.7375 7.91611 12.7375C6.89736 12.7375 4.70674 12.8188 3.78799 12.4563C3.17549 12.2125 2.70361 11.7406 2.45674 11.125C2.09111 10.2031 2.17549 8.01563 2.17549 6.99688C2.17549 5.97813 2.09424 3.78751 2.45674 2.86876C2.70049 2.25626 3.17236 1.78438 3.78799 1.53751C4.70986 1.17188 6.89736 1.25626 7.91611 1.25626C8.93486 1.25626 11.1255 1.17501 12.0442 1.53751C12.6567 1.78126 13.1286 2.25313 13.3755 2.86876C13.7411 3.79063 13.6567 5.97813 13.6567 6.99688C13.6567 8.01563 13.7411 10.2063 13.3755 11.125Z'
        fill={fill}
      />
    </svg>
  )
}
export default InstagramIcon
