export const FETCH_TAKEDOWNS = 'FETCH_TAKEDOWNS'
export const FETCH_TAKEDOWNS_SUCCESS = 'FETCH_TAKEDOWNS_SUCCESS'
export const FETCH_TAKEDOWNS_FAILURE = 'FETCH_TAKEDOWNS_FAILURE'

export const FETCH_IMPERSONATIONS = 'FETCH_IMPERSONATIONS'
export const FETCH_IMPERSONATIONS_SUCCESS = 'FETCH_IMPERSONATIONS_SUCCESS'
export const FETCH_IMPERSONATIONS_FAILURE = 'FETCH_IMPERSONATIONS_FAILURE'

export const FETCH_SINGLE_TAKEDOWN = 'FETCH_SINGLE_TAKEDOWN'
export const FETCH_SINGLE_TAKEDOWN_SUCCESS = 'FETCH_SINGLE_TAKEDOWN_SUCCESS'
export const FETCH_SINGLE_TAKEDOWN_FAILURE = 'FETCH_SINGLE_TAKEDOWN_FAILURE'

export const FETCH_MAILS = 'FETCH_MAILS'
export const FETCH_MAILS_SUCCESS = 'FETCH_MAILS_SUCCESS'
export const FETCH_MAILS_FAILURE = 'FETCH_MAILS_FAILURE'

export const CREATE_TAKEDOWN = 'CREATE_TAKEDOWN'
export const CREATE_TAKEDOWN_SUCCESS = 'CREATE_TAKEDOWN_SUCCESS'
export const CREATE_TAKEDOWN_FAILURE = 'CREATE_TAKEDOWN_FAILURE'

export const GET_TAKEDOWN_SUMMARY = 'GET_TAKEDOWN_SUMMARY'
export const GET_TAKEDOWN_SUMMARY_SUCCESS = 'GET_TAKEDOWN_SUMMARY_SUCCESS'
export const GET_TAKEDOWN_SUMMARY_FAILURE = 'GET_TAKEDOWN_SUMMARY_FAILURE'

export const SEARCH_IMPERSONATOR = 'SEARCH_IMPERSONATOR'
export const SEARCH_IMPERSONATOR_SUCCESS = 'SEARCH_IMPERSONATOR_SUCCESS'
export const SEARCH_IMPERSONATOR_FAILURE = 'SEARCH_IMPERSONATOR_FAILURE'

export const CHECK_IMPERSONATOR_SCORE = 'CHECK_IMPERSONATOR_SCORE'
export const CHECK_IMPERSONATOR_SCORE_SUCCESS =
  'CHECK_IMPERSONATOR_SCORE_SUCCESS'
export const CHECK_IMPERSONATOR_SCORE_FAILURE =
  'CHECK_IMPERSONATOR_SCORE_FAILURE'

export const ADD_IMPERSONATOR = 'ADD_IMPERSONATOR'
export const ADD_IMPERSONATOR_SUCCESS = 'ADD_IMPERSONATOR_SUCCESS'
export const ADD_IMPERSONATOR_FAILURE = 'ADD_IMPERSONATOR_FAILURE'
