import { useEffect } from 'react'

export const useCheckResultType = ({
  setDisableTakedownButton,
  selectedResults,
  watchtowerData
}) => {
  useEffect(() => {
    const checkResults = () => {
      const isNonAdultOrReddit = selectedResults.some((id) => {
        const deepfakeResult = watchtowerData?.deepfake?.data?.find(
          (item) => item?.search_results_id === id
        )
        const impersonationResult = watchtowerData?.impersonations?.data?.find(
          (item) => item?.username_platform === id
        )

        const isNonAdult =
          deepfakeResult && deepfakeResult?.category !== 'adult'

        const isReddit =
          impersonationResult && impersonationResult?.platform === 'reddit'

        return isNonAdult || isReddit
      })

      setDisableTakedownButton(isNonAdultOrReddit)
    }

    checkResults()
  }, [
    selectedResults,
    watchtowerData?.deepfake?.data,
    watchtowerData?.impersonations?.data
  ])
}
