const RedditIcon = ({ width = '16', height = '16', fill }) => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        fill='none'
        viewBox='0 0 48 48'
        id='reddit'
      >
        <path
          fill={fill}
          d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'
        ></path>
        <path
          fill='#fff'
          d='M40.0196 24C40.0196 22.0564 38.4589 20.4957 36.5153 20.4957C35.573 20.4957 34.719 20.8785 34.1006 21.4675C31.7153 19.73 28.4172 18.611 24.7362 18.4932L26.3264 10.984L31.5386 12.103C31.5975 13.4282 32.6871 14.4883 34.0417 14.4883C35.4258 14.4883 36.5448 13.3693 36.5448 11.9852C36.5448 10.6012 35.4258 9.48218 34.0417 9.48218C33.0699 9.48218 32.2159 10.0417 31.8037 10.8662L25.973 9.62942C25.8257 9.59997 25.6491 9.62942 25.5018 9.71776C25.3546 9.8061 25.2662 9.95334 25.2368 10.1006L23.4699 18.4638C19.73 18.5816 16.4024 19.6711 13.9583 21.438C13.3399 20.849 12.4859 20.4662 11.5435 20.4662C9.59998 20.4662 8.03925 22.027 8.03925 23.9705C8.03925 25.384 8.89323 26.6208 10.1006 27.1509C10.0417 27.5043 10.0123 27.8576 10.0123 28.211C10.0123 33.6 16.2846 37.9583 24.0294 37.9583C31.7742 37.9583 38.0466 33.6 38.0466 28.211C38.0466 27.8576 38.0172 27.5043 37.9583 27.1509C39.1656 26.6503 40.0196 25.4429 40.0196 24ZM16.0196 26.503C16.0196 25.119 17.1386 24 18.5227 24C19.9067 24 21.0257 25.119 21.0257 26.503C21.0257 27.8871 19.9067 29.0061 18.5227 29.0061C17.1386 29.0061 16.0196 27.8871 16.0196 26.503ZM29.9484 33.0994C28.2405 34.8073 24.9718 34.9251 24 34.9251C23.0282 34.9251 19.7595 34.8073 18.0515 33.0994C17.7865 32.8343 17.7865 32.4221 18.0515 32.1865C18.3165 31.9214 18.7288 31.9214 18.9644 32.1865C20.054 33.276 22.3509 33.6294 23.9705 33.6294C25.5902 33.6294 27.9165 33.2466 29.0061 32.157C29.2711 31.892 29.6834 31.892 29.919 32.157C30.2135 32.4515 30.2135 32.8638 29.9484 33.0994ZM29.5067 29.0061C28.1227 29.0061 27.0037 27.8871 27.0037 26.503C27.0037 25.119 28.1227 24 29.5067 24C30.8908 24 32.0098 25.119 32.0098 26.503C32.0098 27.8871 30.8908 29.0061 29.5067 29.0061Z'
        ></path>
      </svg>
    </>
  )
}
export default RedditIcon
